<template>
	<div class="clue-list-wrap">
		<!-- <img class="clue-list-img" :src='require(`../assets/img/${articleMixin}.png`)' /> -->
		<div class="clue-list" v-for="(article, index) in articleList" :key="index">
			<header class="clue-list-header">
				<h2 class="section-title">{{ article.section_title }}</h2>
				<h3 class="sub-title">{{ article.title_sub }}</h3>
				<p class="desc">제출된 기록입니다.</p>
			</header>
			<div class="clue-list-body">
				<img class="clue-list-img" :src="require(`/resource/sejong/${makeFilename(article.id)}`)" />
				<div class="clue-item" v-for="(clue, subIndex) in Object.values(article.clues)" :key="subIndex">
					<p class="clue-value" v-html="clue.desc"></p>
				</div>
			</div>
		</div>
		<!-- <div class="button-wrap">
			<router-link :to="{ path: `/session/${session_id}` }" class="back-button">
				이전 화면으로 가기
			</router-link>
		</div> -->
	</div>
</template>

<script>
export default {
	name: 'ResultYeoju',
	metaInfo: {
		// 페이지 제목 설정
		title: '여주 결과 화면',
		titleTemplate: '%s | NOLGONG SCHOOL',
	},
	// props: ["session_id"],
	data() {
		return {
			session_id: null,
			player_id: null,
			current_player: null,
			email: '',
			loaded: false,
			show: true,
			session: null,
			articleList: [],
		};
	},
	components: {},
	computed: {},
	methods: {
		// 유저 정보 조회
		getUser() {
			this.$store
				.dispatch('GET_USER')
				.then((res) => {
					this.email = this.$store.state.email;
					return res;
				})
				.catch((e) => e);
		},
		// 세션 조회
		getSession() {
			this.$store.dispatch('GET_SESSION', { session_id: this.session_id }).then((response) => {
				// 요청이 정상적으로 처리
				if (this.$store.state.serverStatus === 200) {
					// 응답으로 받아온 세션 데이터를 컴포넌트 데이터로 복사한다
					// https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
					// this.session = response.data;

					// ID로 플레이어 찾기
					for (let i = 0; i < response.data.remote_session.players.length; i++) {
						if (response.data.remote_session.players[i].id == this.player_id) {
							this.current_player = response.data.remote_session.players[i];
							console.log(this.current_player);
							break;
						}
					}

					this.articleList = [];
					let keys = Object.keys(this.current_player.status.complete_articles);
					for (let i = 0; i < keys.length; i++) {
						let ca_key = keys[i];
						if (this.current_player.status.open_articles[ca_key]) {
							let article = this.current_player.status.open_articles[ca_key];
							this.articleList.push(article);
						}
					}
					// console.log(this.articleList)

					this.loaded = true;
				}
				// 요청 실패
				if (this.$store.state.serverStatus === 400) {
					console.log('error');
				}
				// 존재하지 않거나 액세스 권한 없는 세션 id로 요청 실패
				if (this.$store.state.serverStatus === 404) {
					this.$router.push('/home');
					// window.location.reload()
					// alert('이런 식으로 들어오시면 안 됩니다.')
				}
				// 토큰 기간 만료로 요청 실패
				if (this.$store.state.serverStatus === 401) {
					this.$router.push('/login');
					// window.location.reload()
					alert('다시 로그인하십시오.');
				}
			});
		},

		makeFilename(id) {
			let filename = Number(id) - 7021 + 1;
			filename = filename + '';
			console.log(filename);
			while (filename.length < 3) {
				filename = '0' + filename;
				// console.log(filename);
			}
			return filename + '.png';
			// return "/resource/sejong/" + filename + ".png";
		},
	},

	mounted() {
		this.session_id = this.$route.params.session_id;
		this.player_id = this.$route.params.player_id;
		// this.session_id = window.location.pathname.split("/")[3];
		this.getUser();
		this.getSession();
	},
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+KR:wght@400;700&display=swap');

/* clue list */

.clue-list-wrap {
	background-color: #f6efde;
	overflow-y: auto;
}

.clue-list-wrap * {
	font-family: 'Noto Serif KR', sans-serif;
	/* margin-top: calc(104px + 40px); */
}

.clue-list-wrap .clue-list:first-child .clue-list-header {
	padding-top: 50px;
}

.clue-list-header {
	width: 100%;
	padding-top: 0;
	background: #fff;
	border-bottom: 1px solid #b0ab9d;
}

.clue-list-header .section-title {
	font-weight: 900;
	font-size: 25px;
	line-height: 32px;
	margin: 0;
	padding: 6px;
	border-bottom: 1px solid #cccccc;
}
.clue-list-header .sub-title {
	margin-top: 6px;
	margin-bottom: 5px;
	font-size: 20px;
}
.clue-list-header .desc {
	margin-bottom: 6px;
	color: #000;
	font-weight: 500;
	font-size: 15px;
	line-height: 23px;
}

.clue-list-img {
	max-width: 100%;
	max-height: 100%;
	margin: 15px auto;
}

/* clue item */
.clue-item {
	width: 334px;
	margin: 0 auto;
	margin-bottom: 37px;
}

.clue-value {
	font-family: inherit;
	font-weight: 600;
	font-size: 16px;
	line-height: 28px;
	font-weight: 600;
	word-break: break-all;
}
</style>
